.AboutUs {
  margin-top: 6rem;
}

.AboutUs section:nth-child(1) {
  background-color: white;
}

.AboutUs section:nth-child(1) .boundaries {
  padding-block: 4rem;
}

.AboutUs section:nth-child(1) .boundaries img {
  display: block;
  margin-inline: auto;
}

.AboutUs section:nth-child(1) .boundaries img:nth-child(1) {
  width: 50%;
  max-width: 250px;
}

.AboutUs section:nth-child(1) .boundaries img:nth-child(2) {
  width: 50%;
  max-width: 250px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

#mission-vision {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
}

#mission-vision > div {
  width: calc(50% - 1.5rem);
  background-color: var(--blue);
  border-radius: 0.5rem;
  text-align: center;
  padding: 10px;
}

#mission-vision > div > h3 {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  text-align: center;
  color: white;
  padding-top: 0.5rem;
}

#mission-vision > div > p {
  color: white;
  padding: 0.5rem;
}

.AboutUs h4 {
  margin-top: 2rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
}

.AboutUs ul {
  margin: 1rem 0 1rem 1rem;
}

.AboutUs ul li {
  margin-bottom: 0.5rem;
  line-height: 1.7rem;
  letter-spacing: 0.2px;
  list-style: none;
}

.AboutUs ul li .bold{
  color: #56d19e;
}

#logo-centelha {
  min-width: 300px;
  width: 50%;
  max-width: 400px;
  margin-top: 2rem;
}

.AboutUs section:nth-child(1) .boundaries small {
  display: block;
  text-align: center;
  min-width: 300px;
  width: 50%;
  max-width: 400px;
  margin: auto;
  color: var(--gray);
}

@media screen and (max-width: 740px) {
  #mission-vision > div {
    width: 100%;
  }

  #mission-vision > div > h3 {
    text-transform: unset;
  }

  #mission-vision > div > p {
    text-align: left;
  }

  .AboutUs h4 {
    text-transform: unset;
  }
}
