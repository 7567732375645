footer {
  position: relative;
  background-color: var(--blue-dark);
}

footer * {
  color: white;
}

footer .boundaries {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.3rem;
  padding-block: 2rem;
}

footer .boundaries > img:nth-child(1) {
  height: 2rem;
}

footer .boundaries > div > a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.1rem;
  text-decoration: none;
}

footer .boundaries > div > a > img {
  height: 1.2rem;
}

footer .boundaries > div > small {
  display: block;
  font-size: 0.8rem;
}
