.vantagens-container {
  background-color: #e0f7fa;
  border-radius: 30px;
  padding: 20px;
  text-align: center;
  margin: 3rem 0 0 0;
}

.vantagens-container h2 {
  margin-bottom: 10px;
  font-weight: bolder;
}

.vantagens-container p {
  margin-bottom: 20px;
  text-align: center;
}

.vantagens-items {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.vantagem-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
  width: calc(100% - 2rem);
  max-width: 200px;
  column-gap: 3rem;
}

.vantagem-icon {
  align-self: center;
  font-size: 0px;
  background-color: #56d19e;
  border-radius: 20px;
  padding: 2rem 1rem;
  width: 7rem;
}

.vantagem-icon img {
  height: 3rem;
}

.vantagem-item h3 {
  margin: 5px 0;
  font-weight: bolder;
  color: #465055;
  padding-top: 1rem;
}

.vantagem-item h4 {
  margin: 0;
  font-size: 15px;
}

/* Media Queries para responsividade */
@media (min-width: 600px) {
  .vantagens-items {
    gap: 20px;
  }

  .vantagem-item {
    width: calc(50% - 2rem);
  }
}

@media (min-width: 900px) {
  .vantagem-item {
    width: calc(33.33% - 2rem);
  }
}

@media (min-width: 1200px) {
  .vantagem-item {
    width: calc(25% - 2rem);
  }
}
