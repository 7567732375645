.AssistDom {
    width: calc(100% - 3rem);
    max-width: 1100px;
    margin: auto;
}

.AssistDom .img-texto {
    display: flex;
    gap: 2rem;
    margin-block: 2rem;
    text-align: left;
    padding-top: 7rem;
    width: calc(100% - 3rem);
    max-width: 1100px;
    margin: auto;
}

.AssistDom .div-img {
    position: relative;
    width: 470px;
    aspect-ratio: 1/1;
    border-radius: 1rem;
    background-size: cover;
    background-position: center;
    box-shadow: 5px 5px 15px rgba(38, 50, 56, 0.2);
    background-image: url("/public/assets/img/looking-at-tablet.jpg");
}

.AssistDom .div-img > img {
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 10%;
}

.AssistDom .texto {
    align-self: flex-end;
    width: 60%;
}

.AssistDom .texto > h3 {
    margin-bottom: 1rem;
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 2.5rem;
    color: #5fbbf6;
}

.AssistDom .texto > p {
    margin-bottom: 0;
    line-height: 1.6rem;
    letter-spacing: 0.2px;
}

.AssistDom .lastContent {
    gap: 2rem;
    margin-block: 2rem;
    text-align: left;
    padding-top: 3rem;
    width: calc(100% - 3rem);
    max-width: 1100px;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.AssistDom .lastText {
    padding-bottom: 0;
    align-self: flex-start;
    margin-bottom: 0;
}

.AssistDom .lastText h2 {
    font-weight: bolder;
}

.AssistDom .lastText h3 {
    color: #ff8349;
    font-weight: bolder;
}

.AssistDom .assisDomtImg {
    display: flex;
    justify-content: center;
    width: 100%;
}

.AssistDom .assisDomtImg img {
    align-self: flex-end;
    border-radius: 30px;
    width: 100%;
    max-width: 350px;
    box-shadow: 5px 5px 15px rgba(38, 50, 56, 0.2);
}

/* Media Queries para responsividade */
@media (min-width: 768px) {
    .AssistDom .img-texto {
        flex-direction: row;
        justify-content: space-between;
    }

    .AssistDom .texto {
        width: 60%;
    }

    .AssistDom .lastContent {
        flex-direction: row;
    }
}