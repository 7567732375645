header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 1;
}

header .boundaries {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  background-color: white;
}

header > .boundaries > a {
  height: 2rem;
  text-decoration: none;
}

header > .boundaries > a > img {
  height: 100%;
}

header > .boundaries > div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

header > .boundaries > div > a {
  position: relative;
  display: flex;
  align-items: center;
  height: 2.5rem;
  font-size: 0.9rem;
  text-decoration: none;
  overflow: hidden;
}

header > .boundaries > div > a:not(#whatsapp-link)::after {
  content: "";
  position: absolute;
  bottom: 0.3rem;
  left: -100%;
  width: 100%;
  display: block;
  height: 2px;
  border-radius: 3px;
  background-color: var(--blue);
  transition: left 0.3s ease-out;
  transition-delay: 0.1s;
}

header > .boundaries > div > a:not(#whatsapp-link):hover::after {
  left: 0%;
  transition-delay: 0s;
}

header > .boundaries > div > #whatsapp-link {
  padding-inline: 2rem;
  outline: 3px solid white;
  border-radius: 30px;
  background-color: var(--blue);
  color: white;
  font-weight: 500;
  transition: outline 0.2s ease-out;
}

header > .boundaries > div > #whatsapp-link:hover {
  outline: 3px solid var(--blue-subtle);
}

#header-dropdown-btn {
  display: none;
  height: 2rem;
  border: none;
  background-color: transparent;
  opacity: 0.8;
  transition: opacity 0.2s ease-out;
}

#header-dropdown-btn:hover {
  opacity: 1;
  cursor: pointer;
}

#header-dropdown-btn > img {
  height: 100%;
}

@media screen and (max-width: 666px) {
  header > .boundaries > div {
    position: absolute;
    top: 6rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border: 1px solid var(--gray-soft);
    border-radius: 0.5rem;
    background-color: white;
    z-index: 21;
  }

  #header-dropdown-btn {
    display: block;
  }
}

#dropdown-toggle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 20;
}
