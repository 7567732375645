@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --light: #eaf6fc;
  --gray-soft: #d3dce0;
  --gray: #727f85;
  --gray-dark: #465055;
  --dark: #263238;
  --blue-dark: #3a9bd8;
  --blue: #5fbbf6;
  --blue-alt: #75c8fc;
  --blue-dim: #cdecff;
  --blue-light: #ddf1ff;
  --orange: #ff8349;
  --orange-light: #ff9f72;
  --orange-dim: #ffe5d9;
  --blue-opaque: #6bb3df;
  --cyan: #649dde;
  --red: #ff6868;
  --green: #33bd7a;
  --green-light: #56d19e;
  --green-dim: #d6ffee;
}

* {
  margin: 0;
  padding: 0;
  color: var(--dark);
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@media screen and (max-width: 450px) {
  html {
    font-size: 14px;
  }
}

.boundaries {
  width: calc(100% - 3rem);
  max-width: 1100px;
  margin: auto;
}

.sect-title {
  padding-top: 1.5rem;
  font-weight: 600;
  font-size: 2rem;
}

.mini-title {
  font-weight: 600;
  font-size: 1.6rem;
}

.bold {
  font-weight: 600;
  color: inherit;
}

.dim {
  color: var(--gray);
}

.emphasize {
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  color: var(--gray);
}

.center {
  text-align: center;
}

.fancy-o {
  background: -webkit-linear-gradient(
    45deg,
    var(--orange),
    var(--orange-light)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fancy-b {
  background: -webkit-linear-gradient(45deg, var(--blue), var(--blue-alt));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

body {
  background-color: var(--light);
}

p {
  line-height: 1.6rem;
  letter-spacing: 0.2px;
}
