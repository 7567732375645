/* HERO BANNER */

#hero-banner {
  margin-top: 6rem;
  background-color: white;
}

#hero-banner > .boundaries {
  position: relative;
  padding-block: 4rem;
  max-width: 1200px;
  background-image: url("/public/assets/img/hero-img.png");
  background-size: 55%;
  background-position: -5% -5%;
  background-repeat: no-repeat;
  overflow: hidden;
}

#hero-banner > .boundaries > a {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 3.5rem;
  aspect-ratio: 1/1;
  transition: 0.2s transform ease-out;
}

#hero-banner > .boundaries > a:hover {
  transform: scale(110%);
  cursor: pointer;
}

#hero-banner > .boundaries > a > img {
  width: 100%;
}

#hero-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 50%;
  margin-left: 50%;
}

#hero-content h1 {
  font-weight: 600;
  font-size: 3rem;
  line-height: 3.5rem;
}

#hero-content #carroussel {
  width: 100%;
  margin-bottom: 1rem;
}

#hero-content #carroussel p {
  font-size: 1.2rem;
}

#carr-dots {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

#carr-dots > div {
  width: 0.5rem;
  aspect-ratio: 1/1;
  margin-top: 0.2rem;
  border-radius: 50%;
  transition: background-color 0.2s ease-out;
}

#hero-content > button {
  height: 3rem;
  font-size: 1rem;
}

@media screen and (max-width: 966px) {
  #hero-banner > .boundaries {
    padding-top: 3rem;
    padding-bottom: 250px;
    background-image: url("/public/assets/img/hero-img-mobile.png");
    background-size: 500px;
    background-position: bottom center;
  }

  #hero-content {
    align-items: center;
    gap: 1rem;
    width: 100%;
    margin-left: unset;
  }

  #hero-content h1 {
    text-align: center;
  }

  #hero-content #carroussel {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
  }

  #hero-content #carroussel p {
    text-align: center;
  }
}

@media screen and (max-width: 707px) {
  #hero-banner > .boundaries > a {
    display: none;
  }
}

@media screen and (max-width: 535px) {
  #hero-banner > .boundaries {
    padding-bottom: 200px;
    background-size: 400px;
  }
}

@media screen and (max-width: 431px) {
  #hero-banner > .boundaries {
    padding-bottom: 150px;
    background-size: 300px;
  }

  #hero-content h1 {
    font-size: 2.5rem;
    line-height: 2.8rem;
  }

  #hero-content #carroussel p {
    font-size: 1rem;
  }
}

/* PHRASE */

#phrase .boundaries {
  position: relative;
  width: calc(100% - 4rem);
  margin-top: 2rem;
  border-radius: 1rem;
  transform: skewX(-5deg);
  box-shadow: 5px 5px 15px rgba(38, 50, 56, 0.2);
  overflow: hidden;
}

#phrase .boundaries #bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 110%;
  transform: translateX(-5%);
}

#phrase .boundaries #bg-video > video {
  width: 100%;
  transform: skewX(5deg);
}

#video-overflow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 110%;
  padding-block: 7rem;
  border-radius: 1rem;
  transform: skewX(5deg);
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0)
  );
}

#video-overflow p {
  color: white;
  font-size: 1.3rem;
  font-weight: 600;
}

#video-overflow img {
  display: inline;
  height: 1rem;
  margin-inline: 0.5rem;
}

#video-overflow h3 {
  color: white;
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.5rem;
}

#playstate-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border: none;
  border-radius: 0.5rem;
  background-color: transparent;
  transform: skewX(5deg);
}

#playstate-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

#playstate-btn > img {
  height: calc(100% - 1rem);
}

@media screen and (max-width: 792px) {
  #phrase .boundaries {
    width: 100%;
    margin-top: unset;
    border-radius: unset;
    transform: unset;
    box-shadow: unset;
  }

  #phrase .boundaries #bg-video {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    transform: unset;
  }

  #phrase .boundaries #bg-video > video {
    width: 100%;
    transform: unset;
  }

  #video-overflow {
    width: 100%;
    padding: 5rem 1rem;
    border-radius: unset;
    transform: skewX(0deg);
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
  }

  #video-overflow p {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2rem;
  }

  #video-overflow img {
    height: 0.8rem;
  }

  #video-overflow h3 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 3.5rem;
  }

  #playstate-btn {
    transform: skewX(0deg);
  }
}

@media screen and (max-width: 614px) {
  #video-overflow {
    padding: 3rem 1rem;
  }

  #playstate-btn {
    top: unset;
    bottom: 1rem;
  }
}

@media screen and (max-width: 394px) {
  #phrase .boundaries #bg-video > video {
    height: 100%;
    width: unset;
    transform: unset;
  }

  #video-overflow {
    padding: 2.5rem 1rem;
  }

  #video-overflow h3 {
    font-size: 1.5rem;
    margin-block: 0.5rem;
    line-height: 2rem;
  }

  #playstate-btn {
    bottom: 0.5rem;
    right: 0.5rem;
  }
}

/* CUSTOMER FIELDS */

#customer-fields .boundaries {
  padding-top: 2rem;
  max-width: 1100px;
}

#customer-fields .boundaries > h2 {
  margin-bottom: 2.5rem;
}

#customer-fields .boundaries > div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-block: 2rem;
}

#customer-fields .boundaries > div > div {
  display: flex;
  align-items: center;
  gap: 4rem;
}

#customer-fields .boundaries > div > div:nth-child(2) {
  flex-direction: row-reverse;
}

#customer-fields .boundaries > div > div:nth-child(2) * {
  text-align: right;
}

#customer-fields .boundaries > div > div > div:nth-child(1) {
  position: relative;
  width: 25vw;
  aspect-ratio: 1/1;
  border-radius: 1rem;
  background-size: cover;
  background-position: center;
  box-shadow: 5px 5px 15px rgba(38, 50, 56, 0.2);
}

#customer-fields .boundaries > div > div > div:nth-child(1) > img {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 10%;
}

#customer-fields .boundaries > div > div:nth-child(1) > div:nth-child(1) {
  background-image: url("/public/assets/img/looking-at-tablet.jpg");
}

#customer-fields .boundaries > div > div:nth-child(2) > div:nth-child(1) {
  background-image: url("/public/assets/img/vaccine.jpg");
}

#customer-fields .boundaries > div > div:nth-child(3) > div:nth-child(1) {
  background-image: url("/public/assets/img/smilling-to-tablet.jpg");
}

#customer-fields .boundaries > div > div > div:nth-child(2) {
  width: 40vw;
}

#customer-fields .boundaries > div > div > div:nth-child(2) > h3 {
  margin-bottom: 1rem;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 2.5rem;
}

#customer-fields .boundaries > div > div > div:nth-child(2) > p {
  margin-bottom: 1rem;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
}

@media screen and (max-width: 1280px) {
  #customer-fields .boundaries > div {
    gap: 4rem;
  }

  #customer-fields .boundaries > div > div {
    align-items: flex-start;
    gap: 4rem;
  }
}

@media screen and (max-width: 1080px) {
  #customer-fields .boundaries > div {
    gap: 4rem;
  }

  #customer-fields .boundaries > div > div > div:nth-child(1) {
    width: 32vw;
  }
}

@media screen and (max-width: 876px) {
  #customer-fields .boundaries > div {
    gap: 5rem;
  }

  #customer-fields .boundaries > div > div > div:nth-child(2) {
    width: 50vw;
  }

  #customer-fields .boundaries > div > div > div:nth-child(2) > h3 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 777px) {
  #customer-fields .boundaries > div {
    gap: 3rem;
  }

  #customer-fields .boundaries > div > div > div:nth-child(1) {
    width: 35vw;
  }

  #customer-fields .boundaries > div > div > div:nth-child(2) > h3 {
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 752px) {
  #customer-fields .boundaries > div > div {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }

  #customer-fields .boundaries > div > div:nth-child(2) {
    flex-direction: inherit;
  }

  #customer-fields .boundaries > div > div:nth-child(2) * {
    text-align: left;
  }

  #customer-fields .boundaries > div > div > div:nth-child(1) {
    width: 100%;
  }

  #customer-fields .boundaries > div > div > div:nth-child(1) > img {
    height: 7%;
  }

  #customer-fields .boundaries > div > div > div:nth-child(2) {
    width: 100%;
  }

  #customer-fields .boundaries > div > div > div:nth-child(2) > h3 {
    font-size: 2rem;
    font-weight: 700;
  }

  #customer-fields .boundaries > div > div > div:nth-child(2) > p {
    margin-bottom: 1rem;
    line-height: 1.6rem;
    letter-spacing: 0.2px;
  }

  #customer-fields .boundaries > div > div > div > button {
    width: 100%;
    margin-top: 0.5rem;
    font-size: 1rem;
    text-align: center !important;
  }
}

/* SECONDARIES */

#secondaries {
  margin-block: 2rem;
}

#secondaries .boundaries {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#secondaries .boundaries > div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
}

#secondaries .boundaries > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: calc(50% - 0.8rem);
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 1px 1px 10px rgba(14, 62, 94, 0.04);
  transition: transform 0.2s ease-out;
}

#secondaries .boundaries > div > div:hover {
  transform: scale(103%);
  cursor: pointer;
}

#secondaries .boundaries > div > div > img {
  height: 2rem;
}

#secondaries .boundaries > div > div > h3 {
  font-weight: 600;
  font-size: 1.1rem;
  text-align: center;
}

@media screen and (max-width: 1046px) {
  #secondaries .boundaries > div > div {
    width: calc(50% - 0.75rem);
  }
}

@media screen and (max-width: 571px) {
  #secondaries .boundaries > div > div {
    width: 100%;
  }
}

/* PRODUCT */

#product {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 3rem;
  background-color: white;
  z-index: 0;
}

#product .boundaries > h2 {
  margin-bottom: 2rem;
}

#product .boundaries > p {
  margin-bottom: 4rem;
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  color: var(--gray);
}

#product .boundaries > div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  margin-top: 2rem;
}

#product .boundaries > div > img {
  width: calc(50% - 2rem);
  border-radius: 0.5rem;
}

#product .boundaries > div > div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: calc(50% - 2rem);
}

#product .boundaries > button {
  display: block;
  height: 3rem;
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-top: 2.5rem;
  font-size: 1rem;
}

@media screen and (max-width: 836px) {
  #product .boundaries > div {
    gap: 2rem;
  }

  #product .boundaries > div > img {
    width: 100%;
    padding: 2rem;
  }

  #product .boundaries > div > div {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  #product .boundaries > div > img {
    padding: unset;
  }

  #product .boundaries > div {
    gap: 4rem;
  }
}

/* BENEFICTS  */

#beneficts {
  position: relative;
  padding-block: 2rem;
  background-color: white;
  z-index: 0;
}

#beneficts .boundaries {
  max-width: 1100px;
}

#beneficts .boundaries > h2 {
  margin-bottom: 2.5rem;
}

#cards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

#cards > div {
  width: calc(50% - 0.5rem);
  height: 9rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 27%;
  box-shadow: 1px 1px 10px rgba(14, 62, 94, 0.09);
  transition: transform 0.2s ease-out;
}

#cards > div:hover {
  transform: scale(103%);
}

#cards > div:nth-child(1) {
  background-image: url("/public/assets/img/usd-circle.svg");
}

#cards > div:nth-child(2) {
  background-image: url("/public/assets/img/back-up.svg");
}

#cards > div:nth-child(3) {
  background-image: url("/public/assets/img/dashboard-monitor.svg");
}

#cards > div:nth-child(4) {
  background-image: url("/public/assets/img/marker-time.svg");
}

#cards > div > h3 {
  margin-bottom: 0.7rem;
  line-height: 1.5rem;
  font-size: 1.3rem;
  font-weight: 600;
}

#cards > div > p {
  margin-top: 0.5rem;
  line-height: 1.3rem;
  color: var(--gray);
  font-size: 1rem;
}

@media screen and (max-width: 878px) {
  #cards > div {
    height: 10rem;
    background-size: 31%;
  }
}

@media screen and (max-width: 686px) {
  #cards > div {
    width: 100%;
  }
}

/* NUMBERS */

#numbers {
  padding-top: 4rem;
  background-color: white;
}

#numbers .boundaries {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#numbers .boundaries > div {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 4rem;
}

#numbers .boundaries > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

#numbers .boundaries > div > div:nth-child(1),
#numbers .boundaries > div > div:nth-child(4) {
  margin-top: 4rem;
}

#numbers .boundaries > div > div > p:nth-child(1) {
  color: var(--green-light);
  font-size: 3.5rem;
  font-weight: 700;
}

#numbers .boundaries > div > div > p:nth-child(1) > span {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

#numbers .boundaries > div > div > p:nth-child(2) {
  margin-top: 0.7rem;
  width: 8rem;
  color: rgba(31, 63, 48, 0.6);
  text-align: center;
  font-size: 1rem;
}

#numbers .boundaries > img {
  width: 50%;
  min-width: 300px;
}

@media screen and (max-width: 818px) {
  #numbers {
    padding-top: 2rem;
  }

  #numbers .boundaries > div {
    flex-wrap: wrap;
    gap: 2rem;
  }

  #numbers .boundaries > div > div:nth-child(1),
  #numbers .boundaries > div > div:nth-child(4) {
    margin-top: unset;
  }

  #numbers .boundaries > div > div > p:nth-child(1) {
    font-size: 3rem;
  }

  #numbers .boundaries > div > div > p:nth-child(2) {
    font-size: 0.9rem;
  }
}

/* FREE DEMO */

#free-demo {
  padding: 0 1rem 2rem 1rem;
  background-color: white;
}

#free-demo .boundaries {
  width: calc(100% - 1rem);
  max-width: 90%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: var(--blue);
}

#free-demo .boundaries > h3 {
  margin-bottom: 1rem;
  color: white;
  text-align: center;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 2.2rem;
}

#free-demo .boundaries > p {
  color: white;
  text-align: center;
  font-size: 1rem;
}

#free-demo .boundaries > button {
  display: block;
  height: 3.5rem;
  margin: auto;
  margin-top: 2rem;
  background-color: white;
  color: var(--blue);
  font-size: 1.1rem;
  text-transform: uppercase;
}

/* FAQ REMINDER */

#faq-reminder {
  padding-block: 2rem;
  background-color: var(--blue-light);
}

#faq-reminder .boundaries > h3 {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.1px;
}

#faq-reminder .boundaries > p {
  color: var(--gray-dark);
}

#faq-reminder .boundaries > div {
  display: flex;
  gap: 2rem;
  width: 100%;
  margin-top: 2rem;
}

#faq-reminder .boundaries > div > button {
  width: calc(50% - 1rem);
  font-size: 1rem;
  text-transform: uppercase;
}

#faq-reminder .boundaries > div > button:nth-child(1) {
  background-color: var(--blue-light);
  outline-color: var(--gray-dark);
  color: var(--dark);
}

#faq-reminder .boundaries > div > button:nth-child(1):hover {
  outline-color: var(--dark);
}

#faq-reminder .boundaries > div > button:nth-child(2) {
  background-color: var(--dark);
  outline-color: var(--dark);
  color: white;
}

#faq-reminder .boundaries > div > button:nth-child(2):hover {
  outline-color: var(--gray-dark);
}

@media screen and (max-width: 648px) {
  #faq-reminder .boundaries > div {
    flex-direction: column;
    gap: 1.5rem;
  }

  #faq-reminder .boundaries > div > button {
    width: 100%;
  }
}

/* whatsapp button */

#whatsapp-floaty {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border-bottom-left-radius: 15px;
  background-color: var(--green);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

#whatsapp-floaty > img {
  width: 60%;
  animation: wiggle 20s infinite forwards ease-in-out;
}

@keyframes wiggle {
  3% {
    transform: rotate(-10deg) scale(105%);
  }
  4% {
    transform: rotate(15deg) scale(105%);
  }
  5% {
    transform: rotate(-10deg) scale(105%);
  }
  6% {
    transform: rotate(15deg) scale(105%);
  }
  8% {
    transform: rotate(0deg) scale(100%);
  }
}

@media screen and (max-width: 400px) {
  #whatsapp-floaty {
    right: 1rem;
    bottom: 1rem;
  }
}
