.LabsDom {
  width: calc(100% - 3rem);
  max-width: 1100px;
  margin: auto;
}

.LabsDom .img-texto {
  display: flex;
  gap: 2rem;
  margin-block: 2rem;
  text-align: left;
  padding-top: 7rem;
  width: calc(100% - 3rem);
  max-width: 1100px;
  margin: auto;
}

.LabsDom .div-img {
  position: relative;
  width: 470px;
  aspect-ratio: 1/1;
  border-radius: 1rem;
  background-size: cover;
  background-position: center;
  box-shadow: 5px 5px 15px rgba(38, 50, 56, 0.2);
  background-image: url("/public/assets/img/vaccine.jpg");
}

.LabsDom .div-img > img {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 10%;
}

.LabsDom .texto {
  align-self: flex-end;
  width: 60%;
}

.LabsDom .texto > h3 {
  margin-bottom: 1rem;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 2.5rem;
  color: #5fbbf6;
}

.LabsDom .texto > p {
  margin-bottom: 0;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
}

.LabsDom .lastContent {
  gap: 2rem;
  margin-block: 2rem;
  text-align: left;
  padding-top: 3rem;
  width: calc(100% - 3rem);
  max-width: 1100px;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.LabsDom .lastText {
  padding-bottom: 0;
  align-self: flex-end;
  margin-bottom: 0;
}

.LabsDom .lastText h2 {
  font-weight: bolder;
}

.LabsDom .lastText h3 {
  color: #ff8349;
  font-weight: bolder;
}

.LabsDom .LabsDomImg {
  display: flex;
  justify-content: center;
  width: 100%;
}

.LabsDom .LabsDomImg img {
  align-self: flex-end;
  border-radius: 30px;
  width: 350px;
  box-shadow: 5px 5px 15px rgba(38, 50, 56, 0.2);
}

/* Media Queries para responsividade */
@media (min-width: 768px) {
  .LabsDom .img-texto {
      flex-direction: row;
      justify-content: space-between;
  }
  
  .LabsDom .texto {
      width: 60%;
  }
  
  .LabsDom .lastContent {
      flex-direction: row;
  }
  }