.FAQ {
  margin-top: 6rem;
}

.FAQ .boundaries {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.FAQ .boundaries h1 {
  margin-bottom: 0.5rem;
}

.faq-img img {
  width: 70%;
  height: 40rem;
  overflow: hidden;
  position: relative;
  justify-content: center;
  width: 100%;
  object-fit: cover;
  object-position: top;
  left: 50%;
  transform: translateX(-50%);
}