.ProductAccordion {
  position: relative;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 10px rgba(14, 62, 94, 0.09);
  overflow: hidden;
}

.ProductAccordion:hover {
  cursor: pointer;
}

.ProductAccordion > h3 {
  padding: 0.8rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.2px;
  user-select: none;
}

.ProductAccordion > ul {
  padding: 0.5rem 1rem 1.5rem 1rem;
}

.ProductAccordion > ul > li {
  position: relative;
  margin-block: 0.3rem;
  padding-left: 1.2rem;
  list-style: none;
  color: var(--gray);
  font-size: 0.9rem;
  line-height: 1.2.rem;
}

.ProductAccordion > ul > li::after {
  position: absolute;
  top: 0.4rem;
  left: 0;
  content: "";
  width: 0.8rem;
  aspect-ratio: 1/1;
  background-image: url("/public/assets/icons/check.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.ProductAccordion > img {
  position: absolute;
  top: calc(0.8rem + 1px);
  right: calc(0.8rem + 1px);
  height: 1.3rem;
  aspect-ratio: 1/1;
  transition: transform 0.3s cubic-bezier(0.85, -0.61, 0.4, 1.09);
}
