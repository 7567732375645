.FAQAccordion {
  padding: 0.8rem 1.2rem;
  border-radius: 0.5rem;
  background-color: white;
  cursor: pointer;
  box-shadow: 1px 1px 10px rgba(14, 62, 94, 0.09);
}

.FAQAccordion h3 {
  position: relative;
  width: calc(100% - 1rem);
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  user-select: none;
}

.FAQAccordion h3 > img {
  position: absolute;
  top: 50%;
  right: -1.5rem;
  height: 1rem;
  aspect-ratio: 1/1;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.85, -0.61, 0.4, 1.09);
}

.FAQAccordion p {
  margin-top: 0.5rem;
  padding: 0.5rem;
  color: var(--gray-dark);
}
