.Button {
  height: 2.5rem;
  padding-inline: 2rem;
  outline: 3px solid white;
  outline-offset: -1px;
  border-radius: 30px;
  border: none;
  background-color: var(--blue);
  color: white;
  font-weight: 500;
  transition-property: outline, background-color;
  transition: 0.2s ease-out;
}

.Button:hover {
  background-color: var(--blue-alt);
  outline: 3px solid var(--blue-light);
  cursor: pointer;
}

.orange {
  background-color: var(--orange);
}

.orange:hover {
  background-color: var(--orange-light);
  outline: 3px solid var(--orange-dim);
}

.green {
  background-color: var(--green);
}

.green:hover {
  background-color: var(--green-light);
  outline: 3px solid var(--green-dim);
}
